import React from "react";

const Home = React.lazy(() => import("./pages/Home"));
const PageError = React.lazy(() => import("./pages/PageError"));

//Auth Pages
const Login = React.lazy(() => import("./pages/AuthPages/Login"));
const Logout = React.lazy(() => import("./pages/AuthPages/Logout"));
const Signup = React.lazy(() => import("./pages/AuthPages/Signup"));
const VerifyEmail = React.lazy(() => import("./pages/AuthPages/VerifyEmail"));
const ForgotPassword = React.lazy(() => import("./pages/AuthPages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/AuthPages/ResetPassword"));

// Settings
const AppSettingsDetail = React.lazy(() => import("./pages/AppSettingsDetail"));
const Quote = React.lazy(() => import("./pages/Quote"));
const QuoteSubmit = React.lazy(() => import("./pages/QuoteSubmit"));
const QuoteList = React.lazy(() => import("./pages/QuoteList"));
const QuoteDetails = React.lazy(() => import("./pages/QuoteDetails"));
const UserList = React.lazy(() => import("./pages/UserList"));
const UserCategoryList = React.lazy(() => import("./pages/UserCategoryList"));
const StockList = React.lazy(() => import("./pages/StockList"));
const DataSync = React.lazy(() => import("./pages/DataSync"));

//  Repair Pages
const RepairPages = React.lazy(() => import("./pages/RepairPages"));

const routes = [
	{
		path: "/",
		component: Home,
		isWithoutLayout: false,
		isTopbarFloat: true,
		exact: true,
	},
	{ 
		path: "/login", 
		component: Login, 
		isWithoutLayout: true,
		exact: true,
	},
	{ 
		path: "/logout", 
		component: Logout, 
		isWithoutLayout: true,
		exact: true,
	},
	{ 
		path: "/signup", 
		component: Signup, 
		isWithoutLayout: true,
		exact: true,
	},
	{ 
		path: "/verify-email/:token", 
		component: VerifyEmail, 
		isWithoutLayout: true,
		exact: true,
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		isWithoutLayout: true,
		exact: true,
	},
	{
		path: "/reset-password/:token",
		component: ResetPassword,
		isWithoutLayout: true,
		exact: true,
	},
	{
		path: "/admin/app-settings",
		component: AppSettingsDetail,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/admin/repair",
		component: RepairPages,
		isWithoutLayout: false,
		exact: true,

	},
	{
		path: "/quotes",
		component: QuoteList,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/quotes/:quoteId",
		component: QuoteDetails,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/admin/users",
		component: UserList,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/admin/user-categories",
		component: UserCategoryList,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/admin/data-sync",
		component: DataSync,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/generate-quote",
		component: Quote,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/generate-quote-submit/:id",
		component: QuoteSubmit,
		isWithoutLayout: false,
		exact: true,
	},
	{
		path: "/stocks",
		component: StockList,
		isWithoutLayout: false,
		exact: true,
	},
	{
		component: PageError,
		isWithoutLayout: true,
		exact: false,
	},
];


export default routes;
